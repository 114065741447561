import {
  InputAdornment,
  Typography,
  TextField,
  FormHelperText,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormikTextField from '../../Common/Formik/FormikTextField';
import FormikTimeTextfield from '../../Common/Formik/FormikTimeTextfield';
import { onKeyDown } from '../../../Utils/dateUtils';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';

const TicketAdditionalDetails = ({ classes, formik, eventInfo }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    setHasLoaded(true);
  }, []);

  const AddExtraZeroToStart = (number) => {
    if (number === undefined || isNaN(Number(number))) {
      return undefined;
    }
    if (typeof number === 'number') {
      if (number < 10) {
        return '0' + number;
      } else {
        return String(number);
      }
    } else if (typeof number === 'string') {
      if (number.length < 2) {
        return '0' + number;
      } else {
        return String(number);
      }
    }
  };

  const GetHoursAndMinutesFromDate = (date) => {
    if (date === undefined) {
      return undefined;
    }
    const formatedDate = new Date(
      new Date(date).getTime() + new Date().getTimezoneOffset() * 60 * 1000
    );
    const hours =
      (new Date(
        formatedDate.getFullYear(),
        formatedDate.getMonth(),
        formatedDate.getDate()
      ).getTime() -
        new Date(1970, 0, 1).getTime()) /
        (60 * 60 * 1000) +
      formatedDate.getHours();
    const minutes = formatedDate.getMinutes();
    return `${AddExtraZeroToStart(hours)}:${AddExtraZeroToStart(minutes)}`;
  };

  return (
    <>
      <Grid className={classes.detailsHeading}>
        <Typography variant="body2">Details (optional)</Typography>
      </Grid>
      <Grid container direction="row" xs={12} spacing={2}>
        <Grid xs={6}>
          <DatePicker
            fullWidth
            variant="inline"
            label="Race start date"
            name="localStartDate"
            format="PPP"
            inputFormat="dd MMM yyyy"
            minDate={new Date(eventInfo.basicInfo.eventStartDate)}
            maxDate={new Date(eventInfo.basicInfo.eventEndDate)}
            value={formik.values.localStartDate}
            error={
              formik.touched.localStartDate &&
              Boolean(formik.errors.localStartDate)
            }
            onChange={(date) => {
              if (date) date = date.toDateString();
              formik.setFieldValue('localStartDate', date, true);
            }}
            onBlur={formik.handleBlur}
            renderInput={(props) => (
              <TextField onKeyDown={onKeyDown} fullWidth {...props} />
            )}
          />
        </Grid>
        <Grid xs={6}>
          <FormikTimeTextfield
            name="localStartTime"
            label="Race start time"
            value={formik.values.localStartTime}
            handleBlur={formik.handleBlur}
          />
        </Grid>
        {hasLoaded && (
          <Grid xs={6}>
            <FormikTextField
              variant="outlined"
              label="Cut-off time"
              type="text"
              name="cutoffTime-input"
              placeholder="HH:MM"
              fullWidth
              defaultValue={
                formik.values.cutoffTime
                  ? GetHoursAndMinutesFromDate(formik.values.cutoffTime)
                  : undefined
              }
              onChange={(e) => {
                const inp = e.target.value.replace(/[^0123456789:]/g, '');
                e.target.value = inp;
                if (inp.length === 0) {
                  formik.setFieldValue('cutoffTime', undefined);
                } else if (inp.split(':').length === 1) {
                  formik.setErrors({
                    cutoffTime: 'Please provide minutes',
                  });
                  if (inp.length > 4) {
                    const time = new Date(inp.slice(0, 4) * 60 * 60 * 1000);
                    formik.setFieldValue('cutoffTime', time);
                    e.target.value = inp.slice(0, 4);
                  } else {
                    const time = new Date(inp * 60 * 60 * 1000);
                    formik.setFieldValue('cutoffTime', time);
                  }
                } else {
                  const [hours, minute] = inp.split(':');
                  const min = Math.min(
                    Number(minute.length > 2 ? minute.substring(1) : minute),
                    59
                  );
                  const hour = hours.slice(0, 4);
                  const time = new Date(
                    hour * 60 * 60 * 1000 + min * 60 * 1000
                  );
                  formik.setFieldValue('cutoffTime', time);
                  if (
                    hours.length > 4 ||
                    minute.length > 2 ||
                    Number(minute) > 59
                  ) {
                    e.target.value = `${hour}:${AddExtraZeroToStart(min)}`;
                  }
                }
              }}
              onBlur={(e) => {
                if (formik.values.cutoffTime !== undefined) {
                  e.target.value = GetHoursAndMinutesFromDate(
                    formik.values.cutoffTime
                  );
                }
              }}
              InputProps={{
                endAdornment: <InputAdornment>Hours</InputAdornment>,
              }}
            />
            {formik.touched.cutoffTime &&
              formik.errors.cutoffTime &&
              typeof formik.errors.cutoffTime === 'string' && (
                <FormHelperText error={true}>
                  {formik.errors.cutoffTime}
                </FormHelperText>
              )}
          </Grid>
        )}
        <Grid xs={6} />
        <Grid xs={6}>
          <FormikTextField
            variant="outlined"
            label="Min age limit"
            placeholder="No limit"
            fullWidth
            name="ageMin"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment>Years old</InputAdornment>,
            }}
          />
        </Grid>
        <Grid xs={6}>
          <FormikTextField
            variant="outlined"
            label="Max age limit"
            placeholder="No limit"
            fullWidth
            name="ageMax"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment>Years old</InputAdornment>,
            }}
          />
        </Grid>
        <Grid xs={6}>
          <FormikTextField
            variant="outlined"
            label="Elevation gain"
            placeholder="Elevation gain"
            fullWidth
            type="number"
            name="elevationGain"
            step={0.1}
            min={0}
            InputProps={{
              endAdornment: <InputAdornment>Meters</InputAdornment>,
            }}
          />
        </Grid>
        <Grid xs={6}>
          <FormikTextField
            variant="outlined"
            label="Elevation drop"
            placeholder="Elevation drop"
            fullWidth
            type="number"
            name="elevationDrop"
            step={0.1}
            min={0}
            InputProps={{
              endAdornment: <InputAdornment>Meters</InputAdornment>,
            }}
          />
        </Grid>
        <Grid xs={12}>
          <FormikTextField
            variant="outlined"
            label="Short description about this ticket"
            rows={4}
            multiline
            fullWidth
            name="description"
            helperText='You can add information here, which is specific to this ticket (for example: what is included in the fee, course details, requirements, etc.)
            This will be displayed in the "More information" section on the ticket together with the race start time and age limit. (Max. 800 characters)'
          />
        </Grid>
        <Grid xs={6} className={classes.detailsMarginTop}>
          <FormikTextField
            variant="outlined"
            label="Ticket limit"
            placeholder="No limit"
            type="number"
            multiline
            fullWidth
            name="ticketLimit"
            helperText='Once the limit is reached, this ticket will be marked as "Sold Out"'
          />
        </Grid>
      </Grid>
    </>
  );
};
export default TicketAdditionalDetails;
