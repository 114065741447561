import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Divider, Drawer, Typography } from '@mui/material';
import { Context, actionTypes } from '../../../Stores/EventInfoStore';
import { methods, useApi } from '../../../Hooks/useApi';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import FormikTextField from '../../Common/Formik/FormikTextField';
import PriceSteps from './PriceSteps';
import TicketAdditionalDetails from './TicketAdditionalDetails';
import TicketDistanceSelector from './TicketDistanceSelector';
import TicketTypeSelector from './TicketTypeSelector';
import makeStyles from '@mui/styles/makeStyles';
import { getValidationSchema } from './TicketFormValidationSchema';
import ConfirmationModal from '../../Common/ConfirmationModal/ConfirmationModal';
import DrawerActions from '../../Common/Drawer/DrawerActions';
import * as distanceMetric from '../../../Consts/distanceMetric';
import { distanceOptions, milesToKm } from '../../../Utils/distanceUtils';
import { format } from 'date-fns';
import Grid from '@mui/material/Unstable_Grid2';
import WrappedDivider from '../../Common/WrappedDivider/WrappedDivider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      width: 567,
    },
  },
  detailsHeading: {
    marginBottom: theme.spacing(2),
  },
  numberInput: {
    '& ::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& [type=number]': { '-moz-appearance': 'none' },
  },
}));
const getCustomDistanceBackendModel = (model) => {
  return {
    distance:
      model.customDistanceUnit === 'km'
        ? model.customDistance
        : model.customDistance * distanceMetric.milesToKmMultiplier,
    measureType: distanceMetric.km,
  };
};
const mapToBackendModel = (model) => ({
  ...model,
  vat: model.vat ?? 0,
  distance:
    model.distance === 'custom'
      ? getCustomDistanceBackendModel(model)
      : {
          distance: model.distance / 1000 /* convert to km */,
          measureType: distanceMetric.km,
        },
  price:
    model.priceType === 'multiple' ? model.priceSteps[0].price : model.price,
});
const emptyTicket = {
  name: '',
  price: '',
  vat: 0,
  distance: '',
  priceSteps: [],
  priceType: 'single',
  ticketType: 'regular',
  customDistance: '',
  customDistanceUnit: '',
  customMetric: '',
  localStartDate: '',
  localStartTime: '',
  minParticipantCount: '',
  numParticipantsIncluded: '',
  additionalTickets: false,
  additionalTeamMemberPrice: '',
  ageMin: '',
  ageMax: '',
  description: '',
  ticketLimit: '',
};

const priceStepDialogContent = {
  title:
    'The first price activation date is scheduled for {price activation date}',
  description_before_sales_start:
    'This ticket will be not available until you activate the sales for this event. At this moment, the first day of sales for this event has been set to {first day of sales}.',
  description_after_sales_start:
    'Please note that you have set the first day of sales for this event as {first day of sales}. This ticket will be not available until {price activation date}. ',
};

const TicketFormDrawer = ({ open, onClose, ticketState, tickets }) => {
  const classes = useStyles();
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [ticket, setTicket] = useState({
    name: '',
    distance: '',
    priceSteps: [],
    customDistanceUnit: null,
  });
  const [eventInfo, dispatch] = useContext(Context);
  const { eventId, editionId } = eventInfo.event;
  const { eventSaleStartDate, eventSaleEndDate, eventCurrency } =
    eventInfo.basicInfo;
  const [validateMultiple, setValidateMultiple] = useState(!ticketState.isNew);

  const handleSave = async (values) => {
    if (!values.vat) {
      values.vat = 0;
    }

    //delete pricesteps if they are not selected
    values.priceSteps = values.priceType === 'single' ? [] : values.priceSteps;
    values.additionalTeamMemberPrice = values.additionalTickets
      ? values.additionalTeamMemberPrice
      : '';
    let backendModel = mapToBackendModel(values);

    let callData = values.id
      ? {
          url: `/ticket/${eventId}/${editionId}/ticket/${ticketState.ticket.id}`,
          method: methods.put,
        }
      : { url: `/ticket/${eventId}/${editionId}/ticket`, method: methods.post };

    const ticketId = await executeApiCall(
      callData.url,
      callData.method,
      backendModel
    );
    if (!ticketId) return;
    const distanceInMeters =
      values.distance === 'custom'
        ? (values.customDistanceUnit === distanceMetric.mi
            ? milesToKm(values.customDistance)
            : values.customDistance) * 1000
        : values.distance;
    let updatedModel = {
      ...values,
      teamTicket: values.ticketType === 'team',
      distance: distanceInMeters,
      active: true,
    };

    if (!values.id) updatedModel.id = ticketId;

    if (
      ticketState.isNew === true &&
      (eventInfo.customQuestions.length || eventInfo.addOns.length)
    ) {
      updateAddonCustomQuestions(ticketId);
    }
    if (ticketState.isEditing) {
      dispatch({
        type: actionTypes.tickets_set,
        payload: {
          ticketIndex: ticketState.ticketIndex,
          ticket: { ...updatedModel },
        },
      });
    } else {
      dispatch({
        type: actionTypes.tickets_add,
        payload: { ...updatedModel },
      });
    }
  };

  const mapTicket = (ticket) => {
    const isCustomDistance = !distanceOptions.includes(ticket.distance);
    return {
      ...ticket,
      ticketType: ticket.teamTicket ? 'team' : 'regular',
      priceType: ticket.priceSteps.length > 0 ? 'multiple' : 'single',
      distance: isCustomDistance ? 'custom' : ticket.distance,
      customDistance: isCustomDistance ? ticket.distance / 1000 : null,
      customDistanceUnit: isCustomDistance ? distanceMetric.km : null,
      additionalTickets: !!ticket.additionalTeamMemberPrice,
    };
  };

  useEffect(() => {
    const { isNew, isDuplicate, isEditing, ticket } = ticketState;
    if (isNew) {
      emptyTicket.localStartDate = eventInfo.basicInfo.eventStartDate;
      setTicket({ ...emptyTicket });
    }
    if (isDuplicate) {
      // Destructure ticket to take ID out
      delete ticket.id;
      setTicket(
        mapTicket({
          ...structuredClone(ticket),
          name: 'Copy of ' + ticket.name,
        })
      ); /* Prepend ticket name with "Copy of" */
    }
    if (isEditing) {
      setTicket(mapTicket(ticket));
    }
  }, [open, ticketState]);

  const handleTicketSave = async (values) => {
    try {
      await handleSave(values);
      const updateModal =
        ticketState.isNew &&
        (eventInfo.tickets.length || eventInfo.addOns.length)
          ? true
          : false;
      onClose(updateModal);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const [showDialog, setShowDialog] = useState(false);
  const [showPriceStepWarningDialog, setShowPriceStepWarningDialog] =
    useState(false);
  const [priceStepWarningDialogContent, setPriceStepWarningDialogContent] =
    useState({ title: '', description: '' });

  const closeDialogDrawer = () => {
    setShowDialog(false);
    onClose();
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const validatePriceStepPrices = useCallback((date, priceStepIndex) => {
    var newDate = new Date(new Date(date).toDateString()).getTime();
    var salesStart = new Date(
      new Date(eventSaleStartDate).toDateString()
    ).getTime();

    var modalContent = priceStepWarningDialogContent;

    if (salesStart < newDate) {
      modalContent.title = priceStepDialogContent.title.replace(
        '{price activation date}',
        format(newDate, 'PPP')
      );
      modalContent.description =
        priceStepDialogContent.description_after_sales_start
          .replace('{first day of sales}', format(salesStart, 'PPP'))
          .replace('{price activation date}', format(newDate, 'PPP'));
    } else if (salesStart > newDate) {
      modalContent.title = priceStepDialogContent.title.replace(
        '{price activation date}',
        format(newDate, 'PPP')
      );
      modalContent.description =
        priceStepDialogContent.description_before_sales_start.replace(
          '{first day of sales}',
          format(salesStart, 'PPP')
        );
    }
    if (salesStart != newDate) {
      setPriceStepWarningDialogContent(modalContent);
      setShowPriceStepWarningDialog(true);
    }
  }, []);

  const updateAddonCustomQuestions = async (ticketId) => {
    eventInfo.customQuestions.forEach(async (question) => {
      if (question.appliedTickets.length !== 0) {
        question.appliedTickets = [...question.appliedTickets, ticketId];
        try {
          await executeApiCall(
            `/ro/events/${eventId}/races/${editionId}/customquestions/${question.id}/tickets`,
            methods.put,
            { ticketsApplied: question.appliedTickets }
          );
        } catch (e) {}
      }
    });
    eventInfo.addOns.forEach(async (addon) => {
      if (addon.availableToTickets.length !== 0) {
        addon.availableToTickets = [...addon.availableToTickets, ticketId];
        dispatch({
          type: actionTypes.addons_update,
          payload: {
            addon,
          },
        });
      }
    });
  };

  const [schema, setSchema] = useState(
    getValidationSchema(tickets, validateMultiple)
  );

  useEffect(() => {
    setSchema(getValidationSchema(tickets, validateMultiple));
  }, [validateMultiple]);

  return (
    <Formik
      enableReinitialize
      initialValues={ticket}
      validationSchema={schema}
      validateOnBlur={true}
      validateOnChange={true}
      onSubmit={async (values) => {
        setValidateMultiple(true);
        await handleTicketSave(values);
      }}
    >
      {(formik) => (
        <Drawer
          anchor="right"
          open={open}
          onClose={() => {
            formik.dirty ? setShowDialog(true) : onClose();
          }}
          className={classes.drawer}
        >
          <form
            onReset={formik.handleReset}
            onSubmit={() => formik.handleSubmit}
          >
            <Grid
              container
              spacing={0}
              justifyContent="center"
              className={classes.root}
            >
              <Grid xs={12} sm={11} container spacing={2}>
                <Grid xs={12}>
                  <Typography variant="h3">
                    {ticketState.isNew === true ? 'Add ticket' : 'Edit ticket'}
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <FormikTextField
                    variant="outlined"
                    label="Ticket name"
                    placeholder="Name of this ticket"
                    required
                    fullWidth
                    name="name"
                  />
                </Grid>

                <TicketDistanceSelector values={formik.values} />

                <WrappedDivider />

                <Divider className={classes.divider} />

                <PriceSteps
                  formik={formik}
                  isAdding={ticketState.isNew}
                  validatePrices={(value, priceStepIndex) =>
                    validatePriceStepPrices(value, priceStepIndex)
                  }
                  setValidateMultiple={setValidateMultiple}
                  classes={classes}
                  currency={eventCurrency}
                  eventSalesStartDate={eventSaleStartDate}
                  eventSalesEndDate={eventSaleEndDate}
                />

                <WrappedDivider />

                <Divider className={classes.divider} />

                <TicketTypeSelector formik={formik} currency={eventCurrency} />

                <Divider className={classes.divider} />

                <TicketAdditionalDetails
                  classes={classes}
                  formik={formik}
                  eventInfo={eventInfo}
                />
                <DrawerActions
                  onClose={() => onClose(false)}
                  handleSubmit={formik.handleSubmit}
                  isSubmitting={formik.isSubmitting}
                  submittingMessage={'Saving...'}
                  submitText={'Save'}
                  cancelText={ticketState.isNew ? 'Cancel' : 'Close'}
                  setValidateMultiple={setValidateMultiple}
                />
              </Grid>
            </Grid>
            <ConfirmationModal
              title={priceStepWarningDialogContent.title}
              content={priceStepWarningDialogContent.description}
              cancelText="OK"
              open={showPriceStepWarningDialog}
              cancel={() => setShowPriceStepWarningDialog(false)}
              cancelColor="primary"
            />
            {showDialog && (
              <ConfirmationModal
                title="You haven't saved your work!"
                content="Closing this will discard any unsaved actions."
                cancelText="Close & Discard"
                confirmText="Save"
                open={showDialog}
                confirm={() => {
                  closeDialog();
                  formik.handleSubmit();
                }}
                cancel={closeDialogDrawer}
              />
            )}
          </form>
        </Drawer>
      )}
    </Formik>
  );
};

export default TicketFormDrawer;
