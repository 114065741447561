import React, { useEffect, useState } from 'react';
import { useParams, Route, Routes } from 'react-router-dom';
import { Typography } from '@mui/material';
import useOrders from '../../Hooks/useOrders';
import { useSnackbar } from 'notistack';
import OrdersPage from './OrdersPage';
import OrderDetails from './Order/OrderDetails';
import useFetchAllPages from '../../Hooks/useFetchAllPages';
import { useEdition } from '../../Hooks/useEdition';

const OrdersBase = () => {
  const { eventId, editionId } = useParams();
  const [fallbackLoading, setFallbackLoading] = useState(false);
  const [fallbackData, setFallbackData] = useState();
  const { orders, participants, isError, isLoading } = useOrders({
    eventId,
    editionId,
    hideError: true,
  });
  const { fetchAllPages } = useFetchAllPages({ eventId, editionId });
  const { enqueueSnackbar } = useSnackbar();
  const { edition } = useEdition(eventId, editionId);

  useEffect(() => {
    if (isError && !fallbackLoading && !fallbackData) {
      setFallbackLoading(true);
      fetchAllPages()
        .then((allData) => {
          // Process allData containing data from all pages
          setFallbackData(allData);
          setFallbackLoading(false);
        })
        .catch((error) => {
          setFallbackLoading(false);
          enqueueSnackbar(error, { variant: 'error' });
        });
    } else {
      setFallbackLoading(false);
    }
  }, [isError]);

  const tableOrders = () => {
    if (orders.length === 0 && isError && fallbackData) {
      return fallbackData?.orders ?? [];
    } else {
      return orders;
    }
  };

  const tableParticipants = () => {
    if (participants.length === 0 && isError && fallbackData) {
      return fallbackData?.participants ?? [];
    } else {
      return participants;
    }
  };

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Order management
      </Typography>
      <Routes>
        <Route
          path="/"
          element={
            <OrdersPage
              orders={tableOrders()}
              participants={tableParticipants()}
              isLoading={isLoading || fallbackLoading}
              event={edition}
            />
          }
        />
        <Route
          path="/:orderId"
          element={<OrderDetails orders={tableOrders()} eventData={edition} />}
        />
      </Routes>
    </>
  );
};

export default OrdersBase;
