import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import {
  InputAdornment,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSnackbar } from 'notistack';
import { CalendarToday } from '@mui/icons-material';
import { getDate, getMonth, getYear } from 'date-fns';
import FormikCountrySelect from '../../../Common/Formik/FormikCountrySelect';
import DrawerBase from '../../../Common/Drawer/DrawerBase';
import FormikTextField from '../../../Common/Formik/FormikTextField';
import WrappedDivider from '../../../Common/WrappedDivider/WrappedDivider';
import { useApi, methods } from '../../../../Hooks/useApi';
import { mutate } from 'swr';
import { orderUrl } from '../../../../Hooks/useOrders';
import { onKeyDown } from '../../../../Utils/dateUtils';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    paddingBottom: `${theme.spacing(4)} !important`,
  },
  paddingTextFieldBottom: {
    paddingBottom: '20px',
  },
  paddingCheckboxRadio: {
    paddingBottom: theme.spacing(1),
  },
}));

const EditParticipantDrawer = ({
  open,
  handleClose,
  participant,
  eventInfo,
  order,
  setParticipant,
  setOrder,
}) => {
  const classes = useStyles();
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const { eventId, editionId } = useParams();
  const [inititalValues, setInititalValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthDate: '',
    nationality: '',
    gender: '',
    address: '',
    postalCode: '',
    city: '',
    state: '',
    country: '',
    teamName: '',
  });

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Invalid Email')
      .required('An email address is required'),
    firstName: yup.string().required('A first name is required'),
    lastName: yup.string().required('A last name is required'),
    nationality: yup.string().required('A nationality is required'),
  });

  const dataURL = (eventId, editionId, orderId) =>
    `/ro/events/${eventId}/races/${editionId}/orders/${orderId}`;

  const mapValues = (values) => {
    const birthDate = new Date(values.birthDate);
    values.birthDate = {
      year: getYear(birthDate),
      month: getMonth(birthDate) + 1,
      day: getDate(birthDate),
    };
    values.birthDate.month.toString();
    for (const [key, val] of Object.entries(values.extraInfo)) {
      const anyAvalableQuestion = availableQuestions.find(
        (question) => String(question.id) === String(key)
      );
      if (anyAvalableQuestion?.type === 'multi_choice') {
        if (typeof val === 'string' && val.length > 0) {
          values.extraInfo[key] = val
            .split('|')
            .filter((option) => option.length > 0)
            .join('|');
        } else if (Array.isArray(val)) {
          values.extraInfo[key] = val
            .filter((option) => option.length > 0)
            .join('|');
        }
      }
    }

    return values;
  };

  const GetSubquestions = (question) => {
    if (question.subQuestions.length === 0) {
      return [];
    }
    const questions = [];
    question.forEach((q) => {
      questions.push(q.id);
      if (q.subQuestions.length > 0) {
        GetSubquestions(q).forEach((subquestion) => {
          questions.push(subquestion);
        });
      }
    });
    return questions;
  };

  const changeParticipant = async (values) => {
    availableQuestions.forEach((q) => {
      if (q.subQuestions.length > 0) {
        q.subQuestions.forEach((question) => {
          if (question.previousAnswer !== values.extraInfo[`${q.id}`]) {
            values.extraInfo[`${question.id}`] = '';
            GetSubquestions(question).forEach(
              (sq) => (values.extraInfo[`${sq}`] = '')
            );
          }
        });
      }
      if (q.type === 'multi_choice') {
        const allowedValues = q.customQuestionValues.map((cqv) => cqv.value);
        if (typeof values.extraInfo[`${q.id}`] === 'string') {
          values.extraInfo[`${q.id}`] = values.extraInfo[`${q.id}`]
            .split('|')
            .filter((option) => allowedValues.includes(String(option)))
            .join('|');
        } else if (Array.isArray(values.extraInfo[`${q.id}`])) {
          values.extraInfo[`${q.id}`] = values.extraInfo[`${q.id}`]
            .filter((option) => allowedValues.includes(String(option)))
            .map((option) => option.value)
            .join('|');
        }
      }
    });
    values = mapValues(values);
    values = {
      teamName: values.teamName,
      participants: [
        {
          id: participant?.id,
          ...values,
          newParticipant: false,
          orderLines: [
            {
              productId:
                order.teamName === ''
                  ? participant?.ticket?.productId
                  : order.orderer.ticket.productId,
            },
          ],
        },
      ],
    };
    try {
      const newParticipant = await executeApiCall(
        dataURL(
          eventInfo.event.eventId,
          eventInfo.event.editionId,
          order.orderId
        ),
        methods.put,
        values
      );
      participant = newParticipant.participants[0];
      setParticipant(newParticipant.participants[0]);
      setOrder(newParticipant);
      handleClose(true);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  React.useEffect(() => {
    let extraInformation = {};
    let available = [];
    if (Object.keys(participant).length > 0) {
      eventInfo.customQuestions
        .filter(
          (fq) =>
            fq.appliedTickets?.length === 0 ||
            fq.appliedTickets?.includes(
              participant?.ticket?.productId ?? order.orderer.ticket.productId
            )
        )
        .forEach((q) => {
          available.push(q);
          const key =
            q.presetQuestionName === null ||
            q.presetQuestionName?.length === 0 ||
            !('presetQuestionName' in q)
              ? q.id
              : q.presetQuestionName;
          const value = participant?.extraInfo?.hasOwnProperty(key)
            ? participant.extraInfo[key]
            : participant?.extraInformationObject?.find(
                (query) => query.questionId === key
              )?.value;
          extraInformation[key] = value === null || undefined ? '' : value;
          if (q.subQuestions.length > 0) {
            q.subQuestions.forEach((sq) => {
              const sqKey = sq.id;
              const sqValue = participant.extraInformationObject.find(
                (q) => q.questionId === sqKey
              )?.value;
              extraInformation[sqKey] = sqValue === undefined ? '' : sqValue;
            });
          }
        });
    }
    available = available.filter((a) => a !== null);
    setAvailableQuestions(available);

    setInititalValues({
      firstName: participant?.firstName,
      lastName: participant?.lastName,
      email: participant?.email,
      birthDate: participant?.birthDate?.date,
      nationality: participant?.nationality,
      gender: participant?.gender?.toLowerCase(),
      extraInfo: extraInformation,
      teamName: order.teamName ? order.teamName : '',
    });
  }, [participant]);

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={true}
        validateOnSubmit={true}
        initialValues={inititalValues}
        onSubmit={async (values) => {
          Promise.all([
            await changeParticipant(values),
            mutate([orderUrl(eventId, editionId), true]),
          ]);
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
          handleChange,
          handleReset,
          handleBlur,
          touched,
          errors,
        }) => (
          <>
            <DrawerBase
              open={open}
              onClose={() => {
                handleClose(false, handleReset);
              }}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              submittingMessage="Saving..."
              submitText="Save"
              cancelText="Cancel"
            >
              <Grid xs={12} className={classes.paddingBottom}>
                <Typography
                  className={classes.paddingBottom}
                  variant="h3"
                  gutterBottom
                >
                  Edit participants information
                </Typography>
                <Grid container spacing={1}>
                  <Grid xs={6}>
                    <FormikTextField
                      className={classes.paddingTextFieldBottom}
                      name="firstName"
                      label="First name"
                      value={values?.firstName}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={6}>
                    <FormikTextField
                      className={classes.paddingTextFieldBottom}
                      name="lastName"
                      label="Last name"
                      value={values.lastName}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12}>
                    <FormikTextField
                      className={classes.paddingTextFieldBottom}
                      name="email"
                      label="Email address"
                      value={values.email}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={6}>
                    <DatePicker
                      className={classes.paddingTextFieldBottom}
                      variant="inline"
                      disableFuture
                      fullWidth
                      label="Date of birth"
                      name="birthDate"
                      value={values?.birthDate}
                      autoOk
                      onChange={(date) =>
                        setFieldValue('birthDate', date, true)
                      }
                      error={touched.birthDate && Boolean(errors.birthDate)}
                      helperText={
                        touched.birthDate &&
                        errors.birthDate &&
                        errors.birthDate
                      }
                      format="PPP"
                      inputFormat="dd MMM yyyy"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <CalendarToday />
                          </InputAdornment>
                        ),
                      }}
                      renderInput={(props) => (
                        <TextField
                          fullWidth
                          onKeyDown={onKeyDown}
                          required
                          {...props}
                          error={touched.birthDate && Boolean(errors.birthDate)}
                          helperText={
                            touched.birthDate &&
                            errors.birthDate &&
                            errors.birthDate
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <FormikCountrySelect
                      className={classes.paddingTextFieldBottom}
                      setCountry={(n) => {
                        values.nationality = n?.code;
                        setFieldValue('nationality', n?.code);
                      }}
                      name="nationality"
                      label="Nationality"
                      country={values?.nationality}
                      required
                    />
                  </Grid>
                  <Grid xs={12}>
                    <RadioGroup
                      value={values?.gender}
                      onChange={handleChange}
                      name="gender"
                      onBlur={handleBlur}
                      defaultValue={inititalValues?.gender}
                    >
                      <FormLabel
                        className={classes.paddingCheckboxRadio}
                        component="legend"
                      >
                        Gender
                      </FormLabel>
                      <FormControlLabel
                        value="f"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="m"
                        control={<Radio />}
                        label="Male"
                      />
                    </RadioGroup>
                  </Grid>
                  <WrappedDivider />
                  {values.teamName !== '' && (
                    <Grid xs={12}>
                      <FormikTextField
                        className={classes.paddingTextFieldBottom}
                        name="teamName"
                        label="Team name"
                        value={values.teamName}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {availableQuestions.some(
                    (q) => q.presetQuestionName === 'club'
                  ) && (
                    <>
                      <Grid xs={12}>
                        <FormikTextField
                          className={classes.paddingTextFieldBottom}
                          name="extraInfo.club"
                          label="Sports club"
                          value={values?.extraInfo?.club || ''}
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}
                  {availableQuestions.some(
                    (q) => q.presetQuestionName === 'idNumber'
                  ) && (
                    <Grid xs={12}>
                      <FormikTextField
                        className={classes.paddingTextFieldBottom}
                        name="extraInfo.idNumber"
                        label="ID or Passport number"
                        value={values?.extraInfo?.idNumber || ''}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {availableQuestions.some(
                    (q) => q.presetQuestionName === 'targets'
                  ) && (
                    <Grid xs={12}>
                      <FormikTextField
                        className={classes.paddingTextFieldBottom}
                        name="extraInfo.targets"
                        label="Target time"
                        value={values?.extraInfo?.targets || ''}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {availableQuestions.some(
                    (q) => q.presetQuestionName === 'size'
                  ) && (
                    <Grid xs={12}>
                      <FormikTextField
                        className={classes.paddingTextFieldBottom}
                        name="extraInfo.size"
                        fullWidth
                        label="T-Shirt size"
                        select
                        value={values?.extraInfo?.size}
                        defaultValue={inititalValues?.extraInfo?.size}
                        onChange={(event) =>
                          setFieldValue('extraInfo.size', event.target.value)
                        }
                      >
                        {eventInfo.customQuestions
                          .find((shirt) => shirt.presetQuestionName === 'size')
                          ?.values.map((size) => (
                            <MenuItem key={size} value={size}>
                              {size}
                            </MenuItem>
                          ))}
                      </FormikTextField>
                    </Grid>
                  )}
                  {availableQuestions.some(
                    (q) => q.presetQuestionName === 'phone'
                  ) && (
                    <>
                      <Grid xs={3}>
                        <FormikTextField
                          className={classes.paddingTextFieldBottom}
                          name="extraInfo.phone.code"
                          label="Country code"
                          value={values?.extraInfo?.phone?.code || ''}
                          fullWidth
                        />
                      </Grid>
                      <Grid xs={9}>
                        <FormikTextField
                          className={classes.paddingTextFieldBottom}
                          name="extraInfo.phone.phone"
                          label="Phone"
                          value={values?.extraInfo?.phone?.phone || ''}
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}
                  {availableQuestions.some(
                    (q) => q.presetQuestionName === 'ice'
                  ) && (
                    <>
                      <WrappedDivider />
                      <Grid xs={12}>
                        <Typography className={classes.paddingTextFieldBottom}>
                          Emergency contact
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <FormikTextField
                          className={classes.paddingTextFieldBottom}
                          name="extraInfo.ice.name"
                          label="Emergency contact name"
                          value={values?.extraInfo?.ice?.name || ''}
                          fullWidth
                        />
                      </Grid>
                      <Grid xs={12}>
                        <FormikTextField
                          className={classes.paddingTextFieldBottom}
                          name="extraInfo.ice.relationship"
                          label="Emergency contact relationship"
                          value={values?.extraInfo?.ice?.relationship || ''}
                          fullWidth
                        />
                      </Grid>
                      <Grid xs={3}>
                        <FormikTextField
                          className={classes.paddingTextFieldBottom}
                          name="extraInfo.ice.code"
                          label="Country code"
                          value={values?.extraInfo?.ice?.code || ''}
                          fullWidth
                        />
                      </Grid>
                      <Grid xs={9}>
                        <FormikTextField
                          className={classes.paddingTextFieldBottom}
                          name="extraInfo.ice.phone"
                          label="Phone"
                          value={values?.extraInfo?.ice?.phone || ''}
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}
                  {availableQuestions.some(
                    (q) => q.presetQuestionName === 'address'
                  ) && (
                    <>
                      <WrappedDivider />
                      <Grid xs={12}>
                        <Typography className={classes.paddingTextFieldBottom}>
                          Address
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <FormikTextField
                          className={classes.paddingTextFieldBottom}
                          name="extraInfo.address.address"
                          label="Address line"
                          value={values?.extraInfo?.address?.address || ''}
                          fullWidth
                        />
                      </Grid>
                      <Grid xs={12}>
                        <FormikTextField
                          className={classes.paddingTextFieldBottom}
                          name="extraInfo.address.zip"
                          label="ZIP/Postal code"
                          value={values?.extraInfo?.address?.zip || ''}
                          fullWidth
                        />
                      </Grid>
                      <Grid xs={12}>
                        <FormikTextField
                          className={classes.paddingTextFieldBottom}
                          name="extraInfo.address.city"
                          label="City"
                          value={values?.extraInfo?.address?.city || ''}
                          fullWidth
                        />
                      </Grid>
                      <Grid xs={12}>
                        <FormikTextField
                          className={classes.paddingTextFieldBottom}
                          name="extraInfo.address.state"
                          label="State"
                          value={values?.extraInfo?.address?.state || ''}
                          fullWidth
                        />
                      </Grid>
                      <Grid xs={12}>
                        <FormikTextField
                          className={classes.paddingTextFieldBottom}
                          name="extraInfo.address.countryCode"
                          label="Country"
                          value={values?.extraInfo?.address?.countryCode || ''}
                          fullWidth
                        />
                      </Grid>
                      <WrappedDivider />
                    </>
                  )}
                  {eventInfo.customQuestions
                    .filter(
                      (fq) =>
                        (fq.presetQuestionName === null ||
                          fq.presetQuestionName?.length === 0) &&
                        (fq.appliedTickets.length === 0 ||
                          fq.appliedTickets.includes(
                            participant?.ticket?.productId ??
                              order.orderer.ticket.productId
                          ))
                    )
                    .map((q) => (
                      <>
                        <Grid xs={12}>
                          {q.type === 'text' && (
                            <FormikTextField
                              className={classes.paddingTextFieldBottom}
                              name={`extraInfo.${q.id}`}
                              label={q.label}
                              value={values?.extraInfo?.[`${q.id}`] || ''}
                              fullWidth
                            />
                          )}
                          {q.type === 'dropdown' && (
                            <FormikTextField
                              className={classes.paddingTextFieldBottom}
                              name={`extraInfo.${q.id}`}
                              fullWidth
                              label={q.label}
                              select
                              value={values?.extraInfo?.[`${q.id}`]}
                              defaultValue={
                                inititalValues?.extraInfo?.[`${q.id}`]
                              }
                              onChange={(event) => {
                                setFieldValue(
                                  `extraInfo.${q.id}`,
                                  event.target.value
                                );
                                if (q.subQuestions.length > 0)
                                  q.subQuestions.forEach((sq) => {
                                    setFieldValue(`extraInfo.${sq.id}`, '');
                                  });
                              }}
                            >
                              {eventInfo.customQuestions
                                .find((cq) => cq.id === q.id)
                                .values.map((val) => (
                                  <MenuItem key={val} value={val}>
                                    {val}
                                  </MenuItem>
                                ))}
                              <MenuItem value={null}>None</MenuItem>
                            </FormikTextField>
                          )}
                          {q.type === 'multi_choice' && (
                            <>
                              <Typography
                                className={classes.paddingCheckboxRadio}
                                gutterBottom
                              >
                                {q.label}
                              </Typography>
                              <FormControl
                                component="fieldset"
                                className={classes.formControl}
                              >
                                <FormGroup>
                                  {q.values.map((qv) => (
                                    <>
                                      <FormControlLabel
                                        className={classes.paddingCheckboxRadio}
                                        control={
                                          <Checkbox
                                            defaultChecked={String(
                                              inititalValues?.extraInfo?.[
                                                `${q.id}`
                                              ]
                                            )
                                              .split('|')
                                              .includes(qv)}
                                            checked={values?.extraInfo?.[
                                              `${q.id}`
                                            ]?.includes(qv)}
                                            onChange={(v) => {
                                              if (
                                                String(
                                                  values.extraInfo[`${q.id}`]
                                                )
                                                  .split('|')
                                                  .includes(v.target.name)
                                              ) {
                                                setFieldValue(
                                                  `extraInfo.${q.id}`,
                                                  values?.extraInfo?.[`${q.id}`]
                                                    .replace(v.target.name, '')
                                                    .replace('undefined', '')
                                                );
                                              } else {
                                                setFieldValue(
                                                  `extraInfo.${q.id}`,
                                                  values?.extraInfo?.[
                                                    `${q.id}`
                                                  ] + `|${v.target.name}`
                                                );
                                              }
                                            }}
                                            name={qv}
                                          />
                                        }
                                        label={qv}
                                      />
                                    </>
                                  ))}
                                </FormGroup>
                              </FormControl>
                            </>
                          )}
                        </Grid>
                        {q.subQuestions.length > 0 &&
                          q.subQuestions.map(
                            (sq) =>
                              (sq.previousAnswer ===
                                values?.extraInfo?.[`${q.id}`] ||
                                values?.extraInfo?.[`${q.id}`]?.includes(
                                  sq.previousAnswer
                                )) && (
                                <Grid xs={12}>
                                  {sq.type === 'multi_choice' && (
                                    <>
                                      <Typography
                                        className={classes.paddingCheckboxRadio}
                                        gutterBottom
                                      >
                                        {sq.label}
                                      </Typography>
                                      <FormControl
                                        component="fieldset"
                                        className={classes.formControl}
                                      >
                                        <FormGroup>
                                          {sq.values.map((qv) => (
                                            <>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    defaultChecked={inititalValues?.extraInfo?.[
                                                      `${sq.id}`
                                                    ]?.includes(qv)}
                                                    checked={values?.extraInfo?.[
                                                      `${sq.id}`
                                                    ]?.includes(qv)}
                                                    onChange={(v) => {
                                                      if (
                                                        values.extraInfo[
                                                          `${sq.id}`
                                                        ].includes(
                                                          v.target.name
                                                        )
                                                      ) {
                                                        setFieldValue(
                                                          `extraInfo.${sq.id}`,
                                                          values?.extraInfo?.[
                                                            `${sq.id}`
                                                          ].replace(
                                                            v.target.name,
                                                            ''
                                                          )
                                                        );
                                                      } else {
                                                        setFieldValue(
                                                          `extraInfo.${sq.id}`,
                                                          values?.extraInfo?.[
                                                            `${sq.id}`
                                                          ] +
                                                            `|${v.target.name}`
                                                        );
                                                      }
                                                    }}
                                                    name={qv}
                                                  />
                                                }
                                                label={qv}
                                              />
                                            </>
                                          ))}
                                        </FormGroup>
                                      </FormControl>
                                    </>
                                  )}
                                  {sq.type === 'dropdown' && (
                                    <>
                                      <FormikTextField
                                        className={
                                          classes.paddingTextFieldBottom
                                        }
                                        name={`extraInfo.${sq.id}`}
                                        fullWidth
                                        label={`${sq.label}`}
                                        select
                                        value={values?.extraInfo?.[`${sq.id}`]}
                                        defaultValue={
                                          inititalValues?.extraInfo?.[
                                            `${sq.id}`
                                          ]
                                        }
                                        onChange={(event) => {
                                          setFieldValue(
                                            `extraInfo.${sq.id}`,
                                            event.target.value
                                          );
                                        }}
                                      >
                                        {sq.values.map((val) => (
                                          <MenuItem key={val} value={val}>
                                            {val}
                                          </MenuItem>
                                        ))}
                                        <MenuItem value={null}>None</MenuItem>
                                      </FormikTextField>
                                    </>
                                  )}
                                  {sq.type === 'text' && (
                                    <FormikTextField
                                      className={classes.paddingTextFieldBottom}
                                      name={`extraInfo.${sq.id}`}
                                      label={sq.label}
                                      value={
                                        values?.extraInfo?.[`${sq.id}`] || ''
                                      }
                                      fullWidth
                                    />
                                  )}
                                </Grid>
                              )
                          )}
                      </>
                    ))}
                </Grid>
              </Grid>
            </DrawerBase>
          </>
        )}
      </Formik>
    </>
  );
};

export default EditParticipantDrawer;
